.yarl__thumbnails {
  display: flex;
  height: 100%;
}
.yarl__thumbnails_top, .yarl__thumbnails_bottom {
  flex-direction: column;
}
.yarl__thumbnails_start .yarl__thumbnails_track, .yarl__thumbnails_end .yarl__thumbnails_track {
  flex-direction: column;
}
.yarl__thumbnails_wrapper {
  flex: 1;
  position: relative;
}
.yarl__thumbnails_container {
  flex: 0 0 auto;
  background-color: var(--yarl__thumbnails_container_background_color, var(--yarl__color_backdrop, #000));
  padding: var(--yarl__thumbnails_container_padding, 16px);
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
}
.yarl__thumbnails_vignette {
  position: absolute;
  pointer-events: none;
  --yarl__thumbnails_vignette_size: 12%;
}
@media (min-width: 1200px) {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 8%;
  }
}
@media (min-width: 2000px) {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 5%;
  }
}
.yarl__thumbnails_top .yarl__thumbnails_vignette, .yarl__thumbnails_bottom .yarl__thumbnails_vignette {
  height: 100%;
  left: 0;
  right: 0;
  background: linear-gradient(to right, var(--yarl__color_backdrop, #000) 0px, transparent var(--yarl__thumbnails_vignette_size, 12%) calc(100% - var(--yarl__thumbnails_vignette_size, 12%)), var(--yarl__color_backdrop, #000) 100%);
}
.yarl__thumbnails_start .yarl__thumbnails_vignette, .yarl__thumbnails_end .yarl__thumbnails_vignette {
  width: 100%;
  top: 0;
  bottom: 0;
  background: linear-gradient(to bottom, var(--yarl__color_backdrop, #000) 0px, transparent var(--yarl__thumbnails_vignette_size, 12%) calc(100% - var(--yarl__thumbnails_vignette_size, 12%)), var(--yarl__color_backdrop, #000) 100%);
}
.yarl__thumbnails_track {
  gap: var(--yarl__thumbnails_thumbnail_gap, 16px);
}
.yarl__thumbnails_thumbnail {
  flex: 0 0 auto;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: var(--yarl__thumbnails_thumbnail_background, #000);
  border: var(--yarl__thumbnails_thumbnail_border, 1px solid var(--yarl__color_button, rgba(255, 255, 255, 0.8)));
  border-radius: var(--yarl__thumbnails_thumbnail_border_radius, 4px);
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
  padding: var(--yarl__thumbnails_thumbnail_padding, 4px);
  width: var(--yarl__thumbnails_thumbnail_width, 120px);
  height: var(--yarl__thumbnails_thumbnail_height, 80px);
  box-sizing: content-box;
}
.yarl__thumbnails_thumbnail_active {
  border-color: var(--yarl__thumbnails_thumbnail_active_border_color, var(--yarl__color_button_active, #fff));
}
.yarl__thumbnails_thumbnail_fadein {
  opacity: 0;
  -webkit-animation: yarl__thumbnails_thumbnail_fadein var(--yarl__thumbnails_thumbnail_fadein_duration, 0.5s) ease-in-out var(--yarl__thumbnails_thumbnail_fadein_delay, 0s) forwards;
          animation: yarl__thumbnails_thumbnail_fadein var(--yarl__thumbnails_thumbnail_fadein_duration, 0.5s) ease-in-out var(--yarl__thumbnails_thumbnail_fadein_delay, 0s) forwards;
}
.yarl__thumbnails_thumbnail_fadeout {
  -webkit-animation: yarl__thumbnails_thumbnail_fadeout var(--yarl__thumbnails_thumbnail_fadeout_duration, 0.5s) ease-in-out var(--yarl__thumbnails_thumbnail_fadeout_delay, 0s) forwards;
          animation: yarl__thumbnails_thumbnail_fadeout var(--yarl__thumbnails_thumbnail_fadeout_duration, 0.5s) ease-in-out var(--yarl__thumbnails_thumbnail_fadeout_delay, 0s) forwards;
  cursor: unset;
}
.yarl__thumbnails_thumbnail_placeholder {
  visibility: hidden;
  cursor: unset;
}
.yarl__thumbnails_thumbnail_icon {
  color: var(--yarl__thumbnails_thumbnail_icon_color, var(--yarl__color_button, rgba(255, 255, 255, 0.8)));
  -webkit-filter: var(--yarl__thumbnails_thumbnail_icon_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8)));
          filter: var(--yarl__thumbnails_thumbnail_icon_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8)));
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: var(--yarl__thumbnails_thumbnail_icon_size, 32px);
  height: var(--yarl__thumbnails_thumbnail_icon_size, 32px);
}
.yarl__thumbnails_contain_image {
  -o-object-fit: contain;
     object-fit: contain;
}

@-webkit-keyframes yarl__thumbnails_thumbnail_fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes yarl__thumbnails_thumbnail_fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes yarl__thumbnails_thumbnail_fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes yarl__thumbnails_thumbnail_fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
